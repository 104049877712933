@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Trap";
  }
}

.bgg {
  background-image: url(./assets//images/Group\ 57.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.map {
  background-image: url(./assets//images//Group\ 24.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.bggContract {
  background-image: url(./assets//images/contractbg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 85vh; */
}

.bggEngineer {
  background-image: url(./assets/images/engineeringbg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bggProject {
  background-image: url(./assets/images/projectBg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bggProcure {
  background-image: url(./assets/images/bgProcure.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bggStory {
  background-image: url(./assets/images/storyBG.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide {
  margin-right: -15px !important;
}

.contractbg {
  background-image: url(./assets/images/coreservicebg1.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.engineerbg {
  background-image: url(./assets/images/coreEngineer.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.projectbg {
  background-image: url(./assets/images/coreProject.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.procurebg {
  background-image: url(./assets/images/coreProcure.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.gridder {
  grid-template-columns: 1fr 1fr;
}
.griddery {
  grid-template-columns: none;
}

#scroll-container {
  overflow: hidden;
}

#scroll-text {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 30s linear infinite;
  -webkit-animation: my-animation 30s linear infinite;
  animation: my-animation 30s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


.slick-prev {
  left: 330px !important;
  top: 300px !important;
  z-index: 100 !important;
}

.slick-next {
  right: 14px !important;
  top: 300px !important;
  z-index: 100 !important;
}

@media (max-width: 600px) {
  .slick-prev {
    top: 150px !important;
    left: 15px !important;
  }
  .slick-next {
    top: 150px !important;
  }
}

.label {
  color: #000;

  text-align: center;
  display: inline-block;

  cursor: pointer;
}
